<template>
  <div
    class="background"
    :style="{
      'background-image': allowBg
        ? 'url(' + webp(require('../assets/recept/background.png')) + ')'
        : null,
    }"
  >
    <div class="container container-padding mt-5 mt-md-0">
      <page-block
        class="page-block"
        title="Окупится за 3 праздника"
        :align="mobile ? 'center' : 'left'"
        :page-body="false"
        :orange-title="!mobile"
      >
        <div v-if="mobile" class="recept-mobile">
          <Splide
            :options="{ rewind: true }"
            aria-label="Напитки от 40 руб/0.5 л"
          >
            <SplideSlide v-for="recept in list" :key="'recept_' + recept.id">
              <div class="output-image">
                <img v-lazy="{ src: recept.output.image }" alt="" />
                <div class="recept-name">
                  {{ recept.name }}
                </div>
                <div class="recept-title">
                  {{ recept.output.title }}
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <div
                  class="position-relative col-image"
                  v-for="item in recept.input"
                  :key="item.text + recept.id"
                >
                  <div class="input-image">
                    <img v-lazy="{ src: item.image_mobile }" alt="" />
                  </div>
                  <div class="input-text">
                    {{ item.text_short }}
                  </div>
                  <div class="input-help">
                    {{ item.help }}
                  </div>
                </div>
              </div>
            </SplideSlide>
          </Splide>
          <div class="text-book">
            Скачайте книгу 100 рецептов и делайте любые напитки на аппарате
            DOMSPIRT у вас дома
          </div>
          <button class="btn-book" @click.prevent="$refs.modalBook.open()">
            СКАЧАТЬ БЕСПЛАТНО<br />“100 РЕЦЕПТОВ НАПИТКОВ”
          </button>
        </div>
        <div v-if="!mobile" class="recept-desktop">
          <div
            class="row recept mx-0 align-items-center"
            v-for="recept in list"
            :key="'recept_' + recept.id"
          >
            <div class="col-auto px-0">
              <div class="recept-name">
                {{ recept.name }}
              </div>
            </div>
            <div class="col px-0">
              <div class="row mx-0 py-1 justify-content-around">
                <div
                  class="col-auto px-0 position-relative"
                  v-for="item in recept.input"
                  :key="item.text + recept.id"
                >
                  <div class="input-image">
                    <img v-lazy="{ src: item.image }" alt="" />
                  </div>
                  <div class="input-text">
                    {{ item.text }}
                  </div>
                  <div class="input-help">
                    {{ item.help }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto px-0">
              <div class="output-text">
                {{ recept.output.text }}
              </div>
            </div>
            <div class="col-auto px-0 position-relative">
              <div class="output-image">
                <img v-lazy="{ src: recept.output.image }" alt="" />
                <div class="text-center">
                  {{ recept.output.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="book-container">
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-lg">
                <div class="book-text">
                  Скачайте книгу 100 рецептов и делайте любые напитки на
                  аппарате DOMSPIRT у вас дома
                </div>
              </div>
              <div class="col-auto">
                <button @click.prevent="$refs.modalBook.open()">
                  <img
                    v-lazy="{
                      src: require('../assets/recept/book.png'),
                    }"
                    alt=""
                  />
                  СКАЧАТЬ БЕСПЛАТНО<br />“100 РЕЦЕПТОВ НАПИТКОВ”
                </button>
              </div>
            </div>
          </div>
        </div>
      </page-block>

      <Modal ref="modalBook" max-width="500">
        <div class="get-book">
          <h3>Получите книгу</h3>
          <img v-lazy="{ src: require('../assets/recept/book.png') }" alt="" />
          <div class="get-book-text">
            Введите свой адрес электронной почты. После подтверждения Вам придет
            книга "100 рецептов крепких напитков Мира" и руководство по
            эксплуатации.
          </div>
          <div class="download-input">
            <input
              type="email"
              :disabled="ordering || success"
              placeholder="Введите Ваш e-mail"
              v-model="form.email"
            />
          </div>
          <button @click="getBook()" v-if="!success">
            {{ ordering ? "Обработка..." : "Получить на e-mail" }}
          </button>
          <div class="success-text" v-if="success">
            Заявка принята. Проверьте Вашу электронную почту!
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import PageBlock from "@/components/PageBlock";
import Modal from "@/ui/Modal";
import { GET_BOOK } from "@/store/modules/order";
import { webp } from "@/plugins/webp";
export default {
  name: "ReceptBlock",
  components: { Modal, PageBlock, Splide, SplideSlide },
  data() {
    return {
      list: [
        {
          id: 1,
          name: "Спирт/водка из сахара (для настоек)",
          input: [
            {
              image: require("../assets/recept/droj.png"),
              image_mobile: require("../assets/recept_mobile/m_droj.png"),
              text: "Дрожжи",
              text_short: "Дрожжи",
              help: "(100 р/100 г) - 200 г",
            },
            {
              image: require("../assets/recept/sugar.png"),
              image_mobile: require("../assets/recept_mobile/m_sugar.png"),
              text: "Сахар",
              text_short: "Сахар",
              help: "(70 р/кг) - 5 кг",
            },
            {
              image: require("../assets/recept/water.png"),
              image_mobile: require("../assets/recept_mobile/m_water.png"),
              text: "Вода",
              text_short: "Вода",
              help: "(20 руб/5 л) - 25 л ",
            },
          ],
          output: {
            text: "Выход чистого спирта 0.5 л/кг сахара",
            image: require("../assets/recept/class.png"),
            title: "50 руб / 0,5 л",
          },
        },
        {
          id: 2,
          name: "Дистилляты из солода (виски)",
          input: [
            {
              image: require("../assets/recept/droj.png"),
              image_mobile: require("../assets/recept_mobile/m_droj.png"),
              text: "Дрожжи",
              text_short: "Дрожжи",
              help: "(100 р/100 г) - 200 г",
            },
            {
              image: require("../assets/recept/pshen.png"),
              image_mobile: require("../assets/recept_mobile/m_pshen.png"),
              text: "Солод (пророщ. зерно)",
              text_short: "Солод",
              help: "(150 р/кг) - 6 кг",
            },
            {
              image: require("../assets/recept/water.png"),
              image_mobile: require("../assets/recept_mobile/m_water.png"),
              text: "Вода",
              text_short: "Вода",
              help: "(20 руб/5 л) - 20 л",
            },
          ],
          output: {
            text: "Выход вискарного дистиллята 0.6 л/кг солода",
            image: require("../assets/recept/pshenprod.png"),
            title: "150 руб/0,5 л",
          },
        },
        {
          id: 2,
          name: "Плодово-ягодные дистилляты (бренди)",
          input: [
            {
              image: require("../assets/recept/droj.png"),
              image_mobile: require("../assets/recept_mobile/m_droj.png"),
              text: "Дрожжи",
              text_short: "Дрожжи",
              help: "(100 р/100 г) - 200 г",
            },
            {
              image: require("../assets/recept/apple.png"),
              image_mobile: require("../assets/recept_mobile/m_apple.png"),
              text: "Яблоки (кальвадос)",
              text_short: "Яблоки",
              help: "(100 р/кг) - 15 кг",
            },
            {
              image: require("../assets/recept/sugar.png"),
              image_mobile: require("../assets/recept_mobile/m_sugar.png"),
              text: "Сахар/фруктоза",
              text_short: "Сахар",
              help: "(70 руб/кг) - 1-3 кг ",
            },
          ],
          output: {
            text: "Выход дистиллята 0,2 л/кг яблок",
            image: require("../assets/recept/kalva.png"),
            title: "250 руб/0,5 л",
          },
        },
      ],
      mobile: false,
      allowBg: false,
      ordering: false,
      success: false,
      errors: [],
      form: {
        email: "",
      },
    };
  },
  methods: {
    getBook() {
      if (!this.ordering && !this.success) {
        this.ordering = true;
        this.errors = [];
        this.$store
          .dispatch(GET_BOOK, this.form.email)
          .then(() => {
            this.success = true;
            this.ordering = false;
          })
          .catch(() => {
            this.ordering = false;
          });
      }
    },
    webp,
  },
  mounted() {
    if (window.innerWidth < 768) this.mobile = true;
    if (window.innerWidth > 991) this.allowBg = true;
  },
};
</script>

<style lang="scss" scoped>
.background {
  @media (min-width: 991px) {
    //background-image: url("../assets/recept/background.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: bottom;
    padding-bottom: 60px;
  }
}
.page-block {
  margin-top: 6vh;
}
.recept {
  padding-top: 17px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin-bottom: 4px;

  background: linear-gradient(125.93deg, #29292b 8.52%, #222222 90.16%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  .recept-name {
    font-weight: 500;
    font-size: 22px;
    width: 240px;
    margin-right: 10px;
    @media (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 1200px) {
      width: 150px;
    }
    @media (max-width: 991px) {
      width: 90px;
      line-height: 17px;
    }
  }
  .input-image {
    text-align: center;
    padding-bottom: 43px;
    img {
      width: 86px;
      @media (max-width: 991px) {
        width: 66px;
      }
    }
  }
  .input-text {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15px;
    display: flex;
    justify-content: center;
    margin: 0 -50px;
    @media (max-width: 991px) {
      margin: 0 -100px;
      font-size: 13px;
    }
  }
  .input-help {
    font-weight: 400;
    color: #b1b1b1;
    font-size: 12px;
    text-align: center;
    position: absolute;
    left: -25px;
    right: 25px;
    bottom: 0px;
    width: calc(100% + 50px);
    @media (max-width: 991px) {
      font-size: 11px;
      //margin: 0 -50px;
      //top: 130px;
      //bottom: auto;
    }
  }
  .output-text {
    font-weight: 400;
    font-size: 14px;
    opacity: 0.5;
    width: 170px;
    text-align: center;
    line-height: 19px;
    padding: 0 20px;
    @media (max-width: 991px) {
      width: 110px;
      padding: 0 10px;
    }
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 13px;
    }
  }
  .output-image {
    width: 140px;
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 991px) {
      font-size: 13px;
      width: 90px;
    }
    img {
      width: 140px;
      @media (max-width: 991px) {
        width: 90px;
      }
    }
    div {
      //position: absolute;
      //left: 0;
      //right: 0;
      //bottom: -30px;
    }
  }
}
.book-container {
  margin-top: 148px;
  background: linear-gradient(
    125.93deg,
    rgba(41, 41, 43, 0.8) 8.52%,
    rgba(34, 34, 34, 0.8) 90.16%
  );
  border-radius: 15px;
  padding: 24px 32px;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;

  @media (max-width: 991px) {
    margin-top: 70px;
  }

  .book-text {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    background-image: linear-gradient(to left, #ffa353, #d95e00);
    color: transparent;
    /* purgecss start ignore */
    background-clip: text;
    -webkit-background-clip: text;
    text-shadow: 0 0 transparent;
    /* purgecss end ignore */
    padding-right: 50px;
    @media (max-width: 991px) {
      text-align: center;
      margin-bottom: 15px;
    }
  }
  button {
    padding: 14px 32px;
    background: #2a93f4;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: none;
    color: #fff;
    position: relative;
    max-width: 358px;
    padding-left: 100px;
    img {
      position: absolute;
      height: 66px;
      left: -30px;
      top: 0;
      bottom: -5px;
      margin: auto;
    }
  }
}
.recept-desktop {
  margin-top: 70px;
}
.recept-mobile {
  .col-image {
    margin-right: -10px;
    margin-bottom: 47px;
  }
  .output-image {
    text-align: center;
    img {
      width: 328px;
      margin-bottom: 13px;
    }
    .recept-name {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 8px;
      padding: 0 40px;
      line-height: 21px;
    }
    .recept-title {
      font-weight: 500;
      font-size: 20px;
      color: #ffa353;
      margin-bottom: 25px;
    }
  }
  .input-image {
    position: relative;
    width: 116px;
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:after {
      content: "";
      background: #292c2f;
      border: 3px solid #444444;
      border-radius: 100px;
      width: 116px;
      height: 116px;
      position: absolute;
      z-index: -1;
    }
    margin-bottom: 12px;
  }
  .input-text {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    //margin-bottom: 2px;
    margin: 0 -50px 2px -50px;
  }
  .input-help {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #c4c4c4;
  }
  .text-book {
    margin-top: 17px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: center;
  }
  .btn-book {
    background: #0086fc;
    border-radius: 7px;
    padding: 16px 23px;
    color: #fefefe;
    font-weight: 700;
    font-size: 20px;
    border: none;
    width: 100%;
  }
}

.get-book {
  img {
    display: block;
    padding: 20px 0;
    margin: 0 auto;
  }
  .get-book-text {
    padding: 20px 0;
    margin-bottom: 15px;
  }
  input {
    background: #f1f1f1;
    border-radius: 10px;
    padding: 12px 50px 12px 20px;
    color: #505050;
    font-size: 18px;
    //border: none;
    width: 100%;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }
  button {
    padding: 12px 0 11px;
    background: #2a93f4;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    border: none;
    width: 100%;
    color: #fff;
  }
  .success-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 18px;

    span {
      color: #0086fc;
      padding: 0 5px;
    }
  }
}
</style>

<style>
.splide__arrow {
  top: 25% !important;
}
</style>
